:root {
    --mdc-theme-primary: #588BFF
}

p, h1 {
    font-family: Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
}

.content {
    min-height: calc(70vh - 2px);
}

footer {
    background: #00000010 0% 0% no-repeat padding-box;
    height: auto;
    min-height: 30vh;
    display: flex;
    flex-direction: row;
    padding-top: 5%;
}

#header {
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
}

#header span {
    width: 52px;
    height: 52px;
    margin: 40px;
    margin-top: 50px;
}

#header span img {
    width: 100%;
    height: 100%;
}
.icon {
    cursor: pointer;
}

#header span:first-child {
    margin-right: 200px;
}

.main-title {
    letter-spacing: 0px;
    color: #000000F2;
    opacity: 1;
    
    font-size: 82px;
    margin: 0;
}

#main-content {
    color: #00000050;
    font: 23px Arial Black;
    text-align: left;

}

#main-content p {
    letter-spacing: -0.48px;

}

#desc {
    display: flex;
    flex-direction: column;
}

#desc h1:last-child {
    color: #00000084;
}

#player {
    width: 657;
    height: 369;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: gray 10px 10px 10px;
}

#intro {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#btns button{
    width: 200px;
    height: 56px;
}

#main-content {
    max-width: 560;
}

#title h1 {
    font: Regular 82px/115px Arial Black;;
}

#title h1:first-child {
    font-size: 102px;
}

#title h1:last-child {
    position: relative;
    left: 211px;
    top: -30px;
}

#line {
    margin: 0 auto;
    width: 98vw;
    height: 0px;

    border: 1px solid #00000050;
    opacity: 1;
}

#latency {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#latency #caption {
    margin-top: 10vh;
}

#caption h1 {
    display: inline-block;
    font: Regular 82px/115px Arial Black;
    margin: 0;
    font-size: 82px;
}

#caption h1:first-child {
    float: left;
    color: #00000084;
}

#caption h1:last-child {
    color: #000000;
}

#caption h1:last-child::before {
    content: " "; 
    display: inline-block; 
}

#vis {
    display: flex;
}

#vis-content {
    width: calc(100% - 545px);
    height: 100%;
}

#vis-content img {
    width: 100%;
    height: 100%;
}

#vis-desc {
    width: 545px;
}

#vis-desc h1 {
    font: Regular 92px/129px Arial Black;
    font-size: 92px;
    color: #000000;
    opacity: 1;
}

#vis-desc h2 {
    font: Regular 32px/45px Arial Black;
    font-size: 32px;
    color: #00000084;
    opacity: 1
}

#vis-desc p {
    text-align: left;
    font: Regular 10px/14px Arial Black;
    font-size: 10px;
    letter-spacing: -0.3px;
    color: #00000050;
    opacity: 1;
}

#vis-desc .top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#vis-desc .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#forw-arrow {
    font-weight: 900;
    font-size: 92px;
    color: #588BFF;
    align-self: center;
}

.copyright {
    text-align: left;
    font: 17px Arial Black;
    letter-spacing: -0.36px;
    color: #00000050;
    opacity: 1;
}

.usages {
    display: flex;
    flex-grow: 3;
    flex-direction: row;
    justify-content: space-around;
}

.usage h1 {
    font-size: 24px;
    margin: 10px 0 10px 0
}

.usage p {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #00000084;
    margin: 10px 0 10px 0
}

.usage {
    display: flex;
    flex-direction: column;

}

.copyright {
    align-self: center;
    margin-right: 5%;
}

#hidden {
    display: none;
    flex-direction: row;
    justify-content: center;
}

#player1 {
    align-self: center;
}

@media screen and (max-width: 1280px) {
    #player {
        display: none;
    }

    #hidden {
        display: flex;
    }
}